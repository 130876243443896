import React from 'react';
import { connect } from 'react-redux';
import Title from 'ui/Title';
import PropTypes from 'prop-types';
import ReactTable from 'ui/ReactTable';
import {
  Button,
} from '@material-ui/core';

import { requesEnrollmentTransactionPaymentExport, requesEnrollmentTransactionPaymentPaginate } from '$redux/enrollment-transaction-payment/actions';

class EnrollmentTransactionPayment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onFetchData = (state) => {
    const { dispatch } = this.props;
    const { pageSize, page } = state;
    dispatch(requesEnrollmentTransactionPaymentPaginate({
      pageSize,
      page,
    }));
  }

  download = () => {
    const { dispatch } = this.props;

    dispatch(requesEnrollmentTransactionPaymentExport());
  };

  render() {
    const {
      enrollmentTransactionPayments, pages, pageSize,
    } = this.props;
    const today = new Date();

    return (
      <div>
        <Title>
          Estado de pagos hoy {`${today.getDate()}/${today.getMonth() + 1}/${today.getFullYear()}`}
          <Button
            onClick={this.download}
          >
            EXPORTAR...
          </Button>
        </Title>
        <ReactTable
          topBorder
          columns={[
            {
              Header: 'Fecha operacion',
              accessor: 'fechaOperacion',
            },
            {
              Header: 'Tipo Operacion',
              accessor: 'tipoOperacion',
            },
            {
              Header: 'Importe Operacion',
              accessor: 'importeOperacion',
            },
            {
              Header: 'Identificador',
              accessor: 'identificador',
            },
            {
              Header: 'Interes',
              accessor: 'interes',
            },
            {
              Header: 'Comision',
              accessor: 'comision',
            },
            {
              Header: 'IVA',
              accessor: 'iva',
            },
            {
              Header: 'Total Cobro',
              accessor: 'totalCobro',
            },
            {
              Header: 'Matricula',
              accessor: 'idMatricula',
            },
            {
              Header: 'Ciclo',
              accessor: 'ciclo',
            },
            {
              Header: 'Email',
              accessor: 'email',
            },
            {
              Header: 'Nombre',
              accessor: 'nombre',
            },
            {
              Header: 'Semestre',
              accessor: 'semestre',
            },
            {
              Header: 'Centro',
              accessor: 'centro',
            },
            {
              Header: 'Total Transaccion',
              accessor: 'totalTransaccion',
            },
          ]}
          manual
          defaultPageSize={pageSize}
          pages={pages}
          data={enrollmentTransactionPayments}
          onFetchData={this.onFetchData} // Request new data when things change
        />
      </div>
    );
  }
}

EnrollmentTransactionPayment.propTypes = {
  dispatch: PropTypes.func.isRequired,
  enrollmentTransactionPayments: PropTypes.array,
  pageSize: PropTypes.number,
  pages: PropTypes.number,
};

EnrollmentTransactionPayment.defaultProps = {
  enrollmentTransactionPayments: [],
  pageSize: 10,
  pages: 0,
};

export default connect(
  state => ({
    enrollmentTransactionPayments: state.enrollmentTransactionPayment.data,
    pages: state.enrollmentTransactionPayment.pages,
  }),
)(EnrollmentTransactionPayment);
