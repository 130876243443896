import utils from '../utils';

import {
  ENROLLMENT_TRANSACTION_PAYMENT_PAGINATE_REQUEST,
  ENROLLMENT_TRANSACTION_PAYMENT_PAGINATE_REQUEST_SUCCEEDED,
  ENROLLMENT_TRANSACTION_PAYMENT_PAGINATE_REQUEST_FAILED,
  ENROLLMENT_TRANSACTION_PAYMENT_EXPORT_REQUEST,
  ENROLLMENT_TRANSACTION_PAYMENT_EXPORT_REQUEST_SUCCEEDED,
  ENROLLMENT_TRANSACTION_PAYMENT_EXPORT_REQUEST_FAILED,
} from './action-types';

const initialState = {
  data: [],
  pages: 0,
  loading: false,
};

const enrollmentTransactionPayment = (state = initialState, action = {}) => {
  switch (action.type) {
    case ENROLLMENT_TRANSACTION_PAYMENT_PAGINATE_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case ENROLLMENT_TRANSACTION_PAYMENT_PAGINATE_REQUEST_SUCCEEDED: {
      const {
        data, pages,
      } = action;
      return {
        ...state,
        data,
        pages,
        loading: false,
      };
    }
    case ENROLLMENT_TRANSACTION_PAYMENT_PAGINATE_REQUEST_FAILED: {
      utils.dialog({
        type: 'warning',
        title: 'Error inesperado',
        message: 'No se ha podido cargar los datos de facturación',
      });
      return {
        ...state,
        loading: false,
      };
    }
    case ENROLLMENT_TRANSACTION_PAYMENT_EXPORT_REQUEST: {
      return {
        ...state,
      };
    }
    case ENROLLMENT_TRANSACTION_PAYMENT_EXPORT_REQUEST_SUCCEEDED: {
      const { data } = action;
      const encodedUri = escape(data);
      const link = document.createElement('a');
      const file = 'exportar-facturacion-aplazame.csv';
      link.setAttribute('href', `data:text/csv;charset=utf-8,%EF%BB%BF${encodedUri}`);
      link.setAttribute('download', file);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      return {
        ...state,
      };
    }
    case ENROLLMENT_TRANSACTION_PAYMENT_EXPORT_REQUEST_FAILED: {
      utils.dialog({
        type: 'warning',
        title: 'Error inesperado',
        message: 'No se ha podido descargar el documento',
      });
      return {
        ...state,
      };
    }
    default:
      return state;
  }
};

export default enrollmentTransactionPayment;
