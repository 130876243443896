import { all } from 'redux-saga/effects';

// sagas
import loginSaga from './login/sagas';
import enrollmentsSaga from './enrollments/sagas';
import enrollmentsWithServicesSaga from './enrollmentsWithServices/sagas';
import messagesSaga from './messages/sagas';
import chargesSaga from './charges/sagas';
import studentSaga from './student/sagas';
import discountsSaga from './discounts/sagas';
import vouchersSaga from './vouchers/sagas';
import semestersSaga from './semesters/sagas';
import collaboratingCentersSaga from './collaborating-centers/sagas';
import gradeSaga from './grades/sagas';
import courseModalitiesSaga from './course-modalities/sagas';
import courseCategoriesSaga from './course-categories/sagas';
import courseCodesSaga from './course-codes/sagas';
import moduleSemesters from './moduleSemesters/sagas';
import academiesSaga from './academies/sagas';
import candidatesSaga from './candidates/sagas';
import billingSaga from './billing/sagas';
import groups from './groups/sagas';
import coursesSaga from './courses/sagas';
import infoChannelsSaga from './info-channels/sagas';
import modulesSaga from './modules/sagas';
import unitsSaga from './units/sagas';
import roadmapSaga from './road-maps/sagas';
import roadmapmoduleSaga from './road-maps-module/sagas';
import staffs from './staffs/sagas';
import regulations from './regulations/sagas';
import paymentModes from './paymentModes/sagas';
import mailsSaga from './mails/sagas';
import splitPaymentsSaga from './splitPayments/sagas';
import servicesSaga from './services/sagas';
import utms from './utms/sagas';
import utmForms from './utm-forms/sagas';
import utmFormCourses from './utm-form-courses/sagas';
import utmStudents from './utm-students/sagas';
import trainingPlans from './training-plans/sagas';
import leadExportar from './lead-exportar/sagas';
import enrollmentSplitPaymentConfirm from './enrollment-split-payment-confirm/sagas';
import orderRecovery from './orderRecovery/sagas';
import enrollmentTransactionPayment from './enrollment-transaction-payment/sagas';

function* rootSaga() {
  yield all([
    ...loginSaga,
    ...enrollmentsSaga,
    ...enrollmentsWithServicesSaga,
    ...messagesSaga,
    ...chargesSaga,
    ...studentSaga,
    ...discountsSaga,
    ...vouchersSaga,
    ...semestersSaga,
    ...collaboratingCentersSaga,
    ...academiesSaga,
    ...candidatesSaga,
    ...billingSaga,
    ...gradeSaga,
    ...groups,
    ...courseCategoriesSaga,
    ...courseModalitiesSaga,
    ...courseCodesSaga,
    ...coursesSaga,
    ...infoChannelsSaga,
    ...modulesSaga,
    ...unitsSaga,
    ...roadmapSaga,
    ...roadmapmoduleSaga,
    ...moduleSemesters,
    ...staffs,
    ...regulations,
    ...paymentModes,
    ...mailsSaga,
    ...splitPaymentsSaga,
    ...servicesSaga,
    ...utms,
    ...utmForms,
    ...utmStudents,
    ...utmFormCourses,
    ...trainingPlans,
    ...leadExportar,
    ...enrollmentSplitPaymentConfirm,
    ...orderRecovery,
    ...enrollmentTransactionPayment,
  ]);
}

export default rootSaga;
