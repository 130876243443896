import {
  select, call, put, takeEvery,
} from 'redux-saga/effects';
import api from 'linkia-api';

import {
  ENROLLMENT_TRANSACTION_PAYMENT_PAGINATE_REQUEST,
  ENROLLMENT_TRANSACTION_PAYMENT_EXPORT_REQUEST,
} from './action-types';
import {
  requesEnrollmentTransactionPaymentPaginateSucceeded,
  requesEnrollmentTransactionPaymentPaginateFailed,
  requesEnrollmentTransactionPaymentExportSucceeded,
  requesEnrollmentTransactionPaymentExportFailed,
} from './actions';

import { tokenSelector } from '../login/selectors';

function* enrollmentTransactionPaymentPaginate(action) {
  const token = yield select(tokenSelector);
  try {
    const { bindingObject } = action;
    const data = yield call(api.enrollmentTransactionPayment.paginate, { token, bindingObject });
    yield put(requesEnrollmentTransactionPaymentPaginateSucceeded(data));
  } catch (e) {
    yield put(requesEnrollmentTransactionPaymentPaginateFailed(e));
  }
}

function* enrollmentTransactionPaymentExport() {
  const token = yield select(tokenSelector);
  try {
    const data = yield call(api.enrollmentTransactionPayment.export, { token });
    yield put(requesEnrollmentTransactionPaymentExportSucceeded(data));
  } catch (e) {
    yield put(requesEnrollmentTransactionPaymentExportFailed(e));
  }
}

export default [
  takeEvery(ENROLLMENT_TRANSACTION_PAYMENT_PAGINATE_REQUEST, enrollmentTransactionPaymentPaginate),
  takeEvery(ENROLLMENT_TRANSACTION_PAYMENT_EXPORT_REQUEST, enrollmentTransactionPaymentExport),
];
