import {
  ENROLLMENT_TRANSACTION_PAYMENT_PAGINATE_REQUEST,
  ENROLLMENT_TRANSACTION_PAYMENT_PAGINATE_REQUEST_SUCCEEDED,
  ENROLLMENT_TRANSACTION_PAYMENT_PAGINATE_REQUEST_FAILED,
  ENROLLMENT_TRANSACTION_PAYMENT_EXPORT_REQUEST,
  ENROLLMENT_TRANSACTION_PAYMENT_EXPORT_REQUEST_SUCCEEDED,
  ENROLLMENT_TRANSACTION_PAYMENT_EXPORT_REQUEST_FAILED,
} from './action-types';

export const requesEnrollmentTransactionPaymentPaginate = bindingObject => ({
  type: ENROLLMENT_TRANSACTION_PAYMENT_PAGINATE_REQUEST,
  bindingObject,
});

export const requesEnrollmentTransactionPaymentPaginateSucceeded = ({ data, pages }) => ({
  type: ENROLLMENT_TRANSACTION_PAYMENT_PAGINATE_REQUEST_SUCCEEDED,
  data,
  pages,
});

export const requesEnrollmentTransactionPaymentPaginateFailed = () => ({
  type: ENROLLMENT_TRANSACTION_PAYMENT_PAGINATE_REQUEST_FAILED,
});
export const requesEnrollmentTransactionPaymentExport = () => ({
  type: ENROLLMENT_TRANSACTION_PAYMENT_EXPORT_REQUEST,
});
export const requesEnrollmentTransactionPaymentExportSucceeded = data => ({
  type: ENROLLMENT_TRANSACTION_PAYMENT_EXPORT_REQUEST_SUCCEEDED,
  data,
});
export const requesEnrollmentTransactionPaymentExportFailed = () => ({
  type: ENROLLMENT_TRANSACTION_PAYMENT_EXPORT_REQUEST_FAILED,
});
