import { combineReducers } from 'redux';

// reducers
import login from './login/reducer';
import student from './student/reducer';
import enrollments from './enrollments/reducer';
import enrollmentsWithServices from './enrollmentsWithServices/reducer';
import messages from './messages/reducer';
import charges from './charges/reducer';
import discounts from './discounts/reducer';
import vouchers from './vouchers/reducer';
import semesters from './semesters/reducer';
import collaboratingCenters from './collaborating-centers/reducer';
import grades from './grades/reducer';
import groups from './groups/reducer';
import moduleSemesters from './moduleSemesters/reducer';
import academies from './academies/reducer';
import candidates from './candidates/reducer';
import courses from './courses/reducer';
import courseCodes from './course-codes/reducer';
import courseModalities from './course-modalities/reducer';
import courseCategories from './course-categories/reducer';
import billing from './billing/reducer';
import infoChannels from './info-channels/reducer';
import modules from './modules/reducer';
import units from './units/reducer';
import roadmaps from './road-maps/reducer';
import roadmapsmodule from './road-maps-module/reducer';
import staffs from './staffs/reducer';
import regulations from './regulations/reducer';
import paymentModes from './paymentModes/reducer';
import mails from './mails/reducer';
import splitPayments from './splitPayments/reducer';
import services from './services/reducer';
import utms from './utms/reducer';
import utmForms from './utm-forms/reducer';
import utmFormCourses from './utm-form-courses/reducer';
import utmStudents from './utm-students/reducer';
import trainingPlans from './training-plans/reducer';
import leadExportar from './lead-exportar/reducer';
import enrollmentSplitPaymentConfirm from './enrollment-split-payment-confirm/reducer';
import orderRecovery from './orderRecovery/reducer';
import enrollmentTransactionPayment from './enrollment-transaction-payment/reducer';

const globalReducer = combineReducers({
  login,
  student,
  enrollments,
  enrollmentsWithServices,
  messages,
  charges,
  discounts,
  vouchers,
  semesters,
  collaboratingCenters,
  grades,
  groups,
  academies,
  candidates,
  billing,
  courseCategories,
  courseModalities,
  courses,
  infoChannels,
  modules,
  units,
  roadmaps,
  roadmapsmodule,
  moduleSemesters,
  staffs,
  regulations,
  paymentModes,
  mails,
  services,
  splitPayments,
  utms,
  utmForms,
  utmFormCourses,
  utmStudents,
  trainingPlans,
  courseCodes,
  leadExportar,
  enrollmentSplitPaymentConfirm,
  orderRecovery,
  enrollmentTransactionPayment,
});

export default globalReducer;
